/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import "@ionic/angular/css/palettes/dark.always.css"; */
/* @import "@ionic/angular/css/palettes/dark.class.css"; */
// @import '@ionic/angular/css/palettes/dark.system.css';
@import 'swiper/css';

.ion-color-good {
    --ion-color-base: var(--ion-color-good);
    --ion-color-base-rgb: var(--ion-color-good-rgb);
    --ion-color-contrast: var(--ion-color-good-contrast);
    --ion-color-contrast-rgb: var(--ion-color-good-contrast-rgb);
    --ion-color-shade: var(--ion-color-good-shade);
    --ion-color-tint: var(--ion-color-good-tint);
}


.ios body {
    –ion-toolbar-background: #0d0d0d;
}


.md body {
    –ion-toolbar-background: #fff;

}

[contenteditable] {
    -webkit-user-select: text;
    user-select: text;
}

.shadow-none {
    box-shadow: none;
}

.bg-white {
    background-color: white;
    --background: white;
}

.bg-primary {
    background-color: var(--ion-color-primary);
    --background: var(--ion-color-primary);
    color: white;
}

.bg-light {
    background-color: var(--ion-color-light);
    --background: var(--ion-color-light);
}

.bg-success {
    background-color: var(--ion-color-success-tint);
    --background: var(--ion-color-success-tint);
}

.bg-transparent {
    background-color: transparent;
    --background: transparent;
}

.border-1 {
    border: 1px solid var(--ion-color-light-shade);
}

.border-start {
    border-left: 1px solid var(--ion-color-light-shade);
}

.border-end {
    border-right: 1px solid var(--ion-color-light-shade);
}

.border-top {
    border-top: 1px solid var(--ion-color-light-shade);
}

.border-bottom {
    border-bottom: 1px solid var(--ion-color-light-shade);
}

video {
    background-color: #0d0d0d;
}

video.border-round {
    border-radius: 0.5rem;
}


.curser-pointer:hover {
    cursor: pointer;
}

.w-100 {
    width: 100%;
}

ion-header.no-shadow {
    box-shadow: none;
}

// ion-modal.modal-lg::part(content) {
//     --height: 90%;
//     // position: absolute; 
//     // top: 5%;
//     --width: 90%;
//     border-radius: 0.5rem;
//     display: block;
// }

ion-modal.fullscreen {
    --width: 100%;
    --height: 100%;
    --border-radius: 0;
}

ion-modal.modal::part(content) {
    border-radius: 0.5rem;
    display: block;
}

.hide {
    display: none;
}

h1.timer-text {
    font-size: 5rem;
}

.ql-container {
    padding-top: 1rem;
    min-height: 10rem;
    border: 1px solid #f4f5f8 !important;
    background-color: #f4f5f8 !important;
    border-radius: 0.4rem;
}

.display-1 {
    font-size: 6rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

.display-2 {
    font-size: 5.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

.display-3 {
    font-size: 4.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

.display-4 {
    font-size: 3.5rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

.display-5 {
    font-size: 3rem !important;
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

.display {
    font-weight: 300 !important;
    line-height: 1.2 !important;
}

app-signin {

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.h-scrolling {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    // .card {
    //   display: inline-block;
    // }

}

.h-scrolling::-webkit-scrollbar {
    display: none;
}

.item-has-focus {
    --highlight-background: #e2e2e2;
}

ion-modal.auto-height {
    --height: auto;
}

ion-modal.auto-height .dialog-page {
    position: relative;
    display: block;
    contain: content;
}

ion-modal.auto-height .dialog-page .inner-content {
    max-height: 80vh;
    overflow: auto;
}

.avatar {
    width: 100%;
    height: 100%;

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 100%;
        height: 100%;

        &.circle {
            border-radius: 50%;
        }

        &.round {
            border-radius: 10%;
        }
    }
}

$font-color: #333;
$font-color-alter: #333;
$message-color: #f1f2f6;

//https://codepen.io/havardob/pen/xxPqXdO
.timeline {
    width: 85%;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: 32px 0 32px 32px;
    border-left: 2px solid #e3e3e3;
    font-size: 0.8rem;

    .timeline-item {
        display: flex;
        gap: 24px;

        &+* {
            margin-top: 24px;
        }

        &+.extra-space {
            margin-top: 48px;
        }
    }

    .new-comment {
        width: 100%;

        input {
            border: 1px solid #e3e3e3;
            border-radius: 6px;
            height: 48px;
            padding: 0 16px;
            width: 100%;

            &::placeholder {
                color: #b2b2b2;
            }

            &:focus {
                border-color: #b2b2b2;
                outline: 0; // Don't actually do this
                box-shadow: 0 0 0 4px #f4f6f8;
            }
        }
    }

    .timeline-item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        margin-left: -41px;
        flex-shrink: 0;
        overflow: hidden;
        box-shadow: 0 0 0 6px #fff;

        svg {
            width: 20px;
            height: 20px;
        }

        &.faded-icon {
            background-color: #e3e3e3;
            color: #7b7b7b;
        }

        &.filled-icon {
            background-color: #688afd;
            color: #fff;
        }
    }

    .timeline-item-description {
        display: flex;
        padding-top: 6px;
        gap: 8px;
        color: #7b7b7b;

        img {
            flex-shrink: 0;
        }

        a {
            color: #3d3d3d;
            font-weight: 500;
            text-decoration: none;

            &:hover,
            &:focus {
                outline: 0; // Don't actually do this
                color: #688afd;
            }
        }

        .update-wrap {
            margin: 0;
            padding: 0.6rem;
            margin-top: 0.4rem;
            border-radius: 10px;
            border: 1px solid #e3e3e3;
        }
    }

    .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        overflow: hidden;
        aspect-ratio: 1 / 1;
        flex-shrink: 0;
        width: 40px;
        height: 40px;

        &.small {
            width: 28px;
            height: 28px;
        }

        img {
            object-fit: cover;
        }
    }

    .comment {
        margin-top: 12px;
        color: #3d3d3d;
        border: 1px solid #e3e3e3;
        box-shadow: 0 4px 4px 0 #f4f6f8;
        border-radius: 6px;
        padding: 16px;
        font-size: 1rem;
    }

    .button {
        border: 0;
        padding: 0;
        display: inline-flex;
        vertical-align: middle;
        margin-right: 4px;
        margin-top: 12px;
        align-items: center;
        justify-content: center;
        font-size: 1rem;
        height: 32px;
        padding: 0 8px;
        background-color: #f4f6f8;
        flex-shrink: 0;
        cursor: pointer;
        border-radius: 99em;

        &:hover {
            background-color: #e3e3e3;
        }

        &.square {
            border-radius: 50%;
            color: #7b7b7b;
            width: 32px;
            height: 32px;
            padding: 0;

            svg {
                width: 24px;
                height: 24px;
            }

            &:hover {
                background-color: #e3e3e3;
                color: #3d3d3d;
            }
        }
    }

    .show-replies {
        color: #b2b2b2;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin-top: 16px;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 1rem;
        cursor: pointer;

        svg {
            flex-shrink: 0;
            width: 24px;
            height: 24px;
        }

        &:hover,
        &:focus {
            color: #3d3d3d;
        }
    }

    .avatar-list {
        display: flex;
        align-items: center;

        &>* {
            position: relative;
            box-shadow: 0 0 0 2px #fff;
            margin-right: -8px;
        }
    }

}

.chat {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 45px 0 0 0;

            a.user {
                margin: -18px 0 0 0;
                display: block;
                color: $font-color-alter;
                text-decoration: none;
                cursor: default;

                img {
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: #f3f3f3;
                }
            }

            .date {
                font-size: 14px;
                color: lighten($font-color-alter, 45);
            }

            .message {
                display: block;
                padding: 10px;
                position: relative;
                color: $font-color;
                font-size: 15px;
                background-color: $message-color;
                border-radius: 6px;
                border: 1px solid #eee;
                max-width: 800px;

                p {
                    margin: 0;
                    padding: 0;
                    transition: all 0.1s;
                }

                video {
                    max-height: 40vh;
                    background-color: black;
                }

                .hider {
                    opacity: 0;
                    z-index: -1;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    margin: -10px;
                    text-align: center;
                    cursor: pointer;
                    transform-style: preserve-3d;
                    transition: all 0.1s;

                    span {
                        display: block;
                        position: relative;
                        top: 50%;
                        font-size: 16px;
                        transform: translateY(-50%);
                    }
                }
            }
        }

        li.plain {
            margin: 0px 0px 0px 38px;
        }

        li.other {
            text-align: right;

            a.user {
                float: right;
            }

            .date {
                float: right;
                margin: -20px 10px 0 0;
            }

            .message {
                margin: 0 58px 0 0;

                &:before {
                    margin: -9px -16px 0 0;
                    right: 0;
                }

                &:after {
                    content: '';
                    right: 0;
                    margin: 0 -15px 0 0;
                }
            }
        }

        li.you {

            a.user {
                float: left;
            }

            .date {
                float: left;
                margin: -20px 0 0 10px;
            }

            .message {
                margin: 0 0 0 58px;

                &:before {
                    margin: -9px 0 0 -16px;
                    left: 0;
                }

                &:after {
                    content: '';
                    left: 0;
                    margin: 0 0 0 -15px;
                }
            }
        }
    }
}

ion-button.fab {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    --box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.metadata-end-wrapper {
    position: absolute;

    top: 10px;
    inset-inline-end: 10px;

    font-size: 0.8rem;

    display: flex;
    align-items: center;
}